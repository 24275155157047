$(document).ready(function () { 
    $(".custom-slick-slider-init").on("init", function (e, slider) {
        var $slick = $(this),
            slidesToShow = slider.options.slidesToShow,
            slideCount = slider.slideCount;

        $slick.toggleClass("slick-half-tiles", (slideCount > slidesToShow));
    }).on("breakpoint", function (e, slider) {
        var $slick = $(this),
            slidesToShow = slider.options.slidesToShow,
            slideCount = slider.slideCount;

        $slick.toggleClass("slick-half-tiles", (slideCount > slidesToShow));
    }).slick({
        prevArrow: "<button type='button' class='fa fa-angle-left arrow-prev'></button>",
        nextArrow: "<button type='button' class='fa fa-angle-right arrow-next'></button>"
    });
});
